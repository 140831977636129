import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import type { Control, FieldValues, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> extends InputTextareaProps {
  control: Control<T>;
  label?: string;
  name: Path<T>;
  wrapperClassName?: string;
  maxCharLimit?: number;
}

const ControlledInputTextArea = <T extends FieldValues>({
  control,
  label,
  name,
  wrapperClassName,
  maxCharLimit,
  ...inputProps
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <div className={wrapperClassName}>
          {label && (
            <label
              htmlFor={label}
              className={classNames({ 'p-error': formState.errors.value })}
            >
              {label}
            </label>
          )}
          <InputTextarea
            {...field}
            className={classNames(
              { 'p-invalid': fieldState.error },
              'w-full mt-2 resize-y'
            )}
            rows={4}
            cols={30}
            onChange={(e) => field.onChange(e.target.value)}
            {...inputProps}
          />
          {fieldState.error ? (
            <small className="p-error">
              {fieldState.error?.message as string}
            </small>
          ) : (
            <small className="p-error">&nbsp;</small>
          )}
          {maxCharLimit && (
            <small
              className={classNames(
                'block text-end -mt-4',
                field.value?.length > maxCharLimit
                  ? 'p-error'
                  : 'text-gray-500 dark:text-gray-300'
              )}
            >
              {field.value?.length || 0} / {maxCharLimit}
            </small>
          )}
        </div>
      )}
    />
  );
};

export default ControlledInputTextArea;
